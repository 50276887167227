












import { Component, Vue } from "vue-property-decorator";
import LayoutSimple from "@/layouts/LayoutSimple.vue";
import ErrorCard from "@/components/utility/ErrorCard.vue";
import { LocalHostToPartnerMapper } from "@/lib/utility/local-host-to-partner-mapper";

@Component({
  components: {
    LayoutSimple,
    ErrorCard
  }
})
export default class PartnerContactError extends Vue {
  items = [
    {
      text: "Website",
      disabled: false,
      href: "https://www.mmmint.ai"
    },
    {
      text: "Impressum",
      disabled: false,
      href: "https://www.mmmint.ai/impressum/"
    },
    {
      text: "AGB",
      disabled: false,
      href: "https://www.mmmint.ai/agbs/"
    }
  ];

  loading = false;

  async reload() {
    try {
      this.loading = true;
      await LocalHostToPartnerMapper.tryGetPartnerByHost(window.location.hostname);
      this.$router.push({ path: "/form" });
    } catch (error) {
      this.$log.error(error);
      this.$toast("Fehler beim Laden der Anwendung.");
      this.$router.push({ name: "PartnerContactError" });
    } finally {
      this.loading = false;
    }
  }
}
